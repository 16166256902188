<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          สร้างใบโอนสินค้า
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row text-sm-start d-flex justify-content-center">
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label"
              >เลขที่เอกสาร</label
            >
            <input disabled class="form-control mb-4" />
          </div>
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label"
              >วันที่เอกสาร</label
            >
            <input disabled class="form-control mb-4" />
          </div>
        </div>

        <div class="row text-sm-start d-flex justify-content-center mb-4">
          <div class="col-sm-10">
            <label for="selectMainProduct" class="form-label">โอนจาก</label>
            <input disabled class="form-control mb-4" />
          </div>
        </div>

        <div class="row text-end">
          <div class="col-sm-12">
            <button
              type="button"
              class="btn btn-sm btn-light btn-success"
              style="font-size: 14px; font-weight: bold"
              @click="addItem"
            >
              + เพิ่ม
            </button>
            <AddItem
              :dialogAddItem="dialogAddItem"
              @closeDialogAddItem="closeDialogAddItem"
            />
          </div>
        </div>

        <div class="table-responsive" style="padding-top: 3px">
          <table
            class="
              table table-hover table-rounded table-striped
              border
              gy-7
              gs-7
            "
          >
            <thead>
              <tr
                style="width: 100%"
                class="
                  fw-bold
                  fs-5
                  text-gray-800
                  border-bottom-2 border-gray-200
                  mw-200px
                "
              >
                <th>#</th>
                <th>Item code</th>
                <th>Item detail</th>
                <th>PO_QTY</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Vat</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>1</td>
                <td>100001</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row d-flex justify-content-center mt-4">
          <div class="col-sm-8">
            <label for="selectMainProduct" class="form-label">หมายเหตุ</label>
            <input class="form-control mb-4" />
            <label for="selectMainProduct" class="form-label"
              >โอนเข้าคลัง</label
            >
            <input class="form-control mb-4" />
          </div>

          <div class="col-sm-4">
            <label for="selectMainProduct" class="form-label"
              >มูลค่ารวม(ก่อนVAT)</label
            >
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label">ส่วนลดรวม</label>
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label">VAT</label>
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label"
              >มูลค่าสุทธิ</label
            >
            <input disabled class="form-control" />
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar">
          <button
            type="submit"
            class="btn btn-sm btn-light btn-primary me-2"
            style="font-size: 14px; font-weight: bold"
            @click="submit"
          >
            บันทึก
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light"
            style="font-size: 14px; font-weight: bold"
            @click="cancel"
          >
            ยกเลิก
          </button>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import AddItem from "./components/dialogAddItem.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  components: {
    AddItem,
  },
  setup() {
    document.title = "CHOMTHANA | สร้างใบโอนสินค้า";
  },
  data: () => ({
    searchProduct: "",
    isSubmit: false,
    isValidRetailPrice: false,
    dialogAddItem: false,
    form: {
      selectMainProduct: "",
      selectSecondaryProduct: "",
      ProductName: "",
      detail: "",
      barcode: "",
      pog: "",
      retailPrice: "",
      proPrice: "",
      wholesalePrice: "",
      min: "",
      max: "",
    },
    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
  }),

  methods: {
    async submit() {
      await this.validateForm();
      this.isSubmit = true;
      // Swal.fire({
      //   icon: "success",
      //   title: "เพิ่มสำเร็จ",
      //   toast: true,
      //   position: "top-end",
      //   showConfirmButton: false,
      //   timer: 2000,
      //   timerProgressBar: true,
      // }).then(() => {
      //   this.$router.go(-1);
      // });
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    addItem() {
      this.dialogAddItem = true;
    },
    closeDialogAddItem() {
      this.dialogAddItem = false;
    },
  },
};
</script>
